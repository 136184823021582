/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  createMuiTheme,
  CssBaseline,
  responsiveFontSizes,
  ThemeProvider,
  useMediaQuery,
  withStyles,
} from "@material-ui/core"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./Layout.css"

import NavBar from "./NavBar"
import Footer from "./Footer"
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  main: {
    minHeight: "calc(100vh - 350px)",
    paddingTop: 60, // space for navbar
  },
})

const Layout = ({ children, isFrontPage }) => {
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
  //
  // const [isDarkMode, setIsDarkMode] = useState(prefersDarkMode)

  const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    "@global": {
      ".MuiDivider-light": {
        backgroundColor: "rgba(255, 255, 255, 0.7)",
      },
      ".MuiButton-label": {
        fontWeight: 600,
      },
    },
  })(() => null)

  const light = {
    palette: {
      type: "light",
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#6111b1",
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        main: "#fff",

        contrastText: "#6111b1",

        // dark: will be calculated from palette.secondary.main,
      },
      text: {
        primary: "rgba(38, 50, 56, 1.0)",
        secondary: "rgba(38, 50, 56, 0.87)",
        disabled: "rgba(38, 50, 56, 0.38)",
      },

      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      // contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      // tonalOffset: 0.2,
    },
  }

  const dark = {
    palette: {
      type: "dark",
    },
  }

  let theme = createMuiTheme({
    overrides: {
      MuiPaper: {
        elevation20: {
          boxShadow: "0 0 36px rgba(0, 0, 0, 0.1)",
        },
      },
    },
    typography: {
      fontFamily: [
        "Source Sans Pro",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h2: {
        fontSize: "3.5rem",
        fontWeight: 600,
      },
      h3: {
        fontSize: "2rem",
        fontWeight: 600,
      },
      h4: {
        fontSize: "1.7rem",
        fontWeight: 500,
      },
      h5: {
        fontSize: "1.50rem",
        fontWeight: 500,
      },
      body1: {
        fontSize: "1.25rem",
      },
      body2: {
        fontSize: "1rem",
      },
      subtitle2: {
        fontSize: "1.7rem",
        lineHeight: 1.5,
        fontWeight: 400,
      },
    },
    ...light,
    // ...(isDarkMode ? dark : light),
  })
  theme = responsiveFontSizes(theme)

  const classes = useStyles()

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalCss />
      <NavBar isFrontPage={isFrontPage} />

      {/*<Container maxWidth={"sm"} css={css`text-align: center`}>*/}
      {/*  <Button onClick={() => navigate("/")}>Home</Button>*/}
      {/*  <Button onClick={() => setIsDarkMode(!isDarkMode)}>Toggle Theme</Button>*/}
      {/*</Container>*/}

      <main className={classes.main}>{children}</main>

      <Footer />
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
