import { Button, Hidden, makeStyles, Container } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { withPrefix } from "gatsby"
import React from "react"
import Logo from "../assets/svgs/Logo_white.svg"
import DevToIcon from "../assets/svgs/social/devto.svg"
import SpectrumIcon from "../assets/svgs/social/spectrum.svg"
import TwitterIcon from "../assets/svgs/social/twitter-brands.svg"
import YouTubeIcon from "../assets/svgs/social/youtube.svg"
import ButtonLink from "./ButtonLink"

const useIconStyles = makeStyles({
  icon: {
    color: "#fff",
    "&:hover": {
      color: "rgba(64,10,104,0.77)",
    },
    width: 24,
    height: 24,
    textAlign: "center",
    verticalAlign: "middle",
  },
  svg: {
    width: 24,
    height: 24,
    textAlign: "center",
    verticalAlign: "middle",
  },
})

const SocialLink = ({ icon: Icon, href, title }) => {
  const classes = useIconStyles()

  return (
    <a
      className={classes.icon}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
    >
      <Icon className={classes.svg} />
    </a>
  )
}


const useStyles = makeStyles({
  root: {
    backgroundImage: props => `url(${props.svg})`,
    backgroundSize: "cover",
    // padding: "28rem 0px 4rem",
    width: "100%",
    maxWidth: "100%",
    height: "350px",
    display: "block",
    backgroundPosition: "center top",
    backgroundRepeat: "no-repeat",
  },
  logo: {
    maxWidth: 200,
    marginLeft: 8,
  },
  wrapper: {
    // display: "flex",
    // alignItems: "center",
    height: 350,
    paddingTop: 140,
  },
  // grid: {
  //   height: 350,
  //   paddingTop: 50,
  // },
  // gridItem: {
  //   // height: 350,
  // },
  buttonLink: {
    padding: "0 8px",
    textTransform: "none",
    "& .MuiButton-label": {
      fontWeight: 400,
    },
  },
  socials: {
    marginLeft: 8,
    color: "#fff",
    marginTop: 16,
    "& > a": {
      marginRight: 16,
    },
  },
  links: {
    color: "#fff",
    marginTop: 16,
    display: "flex",
    flexWrap: "wrap",
  },
  divider: {
    fontSize: 11,
    verticalAlign: "middle",
  },
})

const Footer = ({}) => {
  const classes = useStyles({ svg: withPrefix("/svgs/footer_bg.svg") })

  return (
    <footer className={classes.root}>
      <Container maxWidth={"lg"}>
        {/*<Grid*/}
        {/*  container*/}
        {/*  className={classes.grid}*/}
        {/*  justify={"flex-start"}*/}
        {/*  alignItems={"center"}*/}
        {/*>*/}
        {/*  <Grid item className={classes.gridItem} xs={12}>*/}
        <div className={classes.wrapper}>
            <Logo className={classes.logo} />
            <div className={classes.socials}>
              <SocialLink
                title={"Twitter"}
                icon={TwitterIcon}
                href={"https://twitter.com/MobileUI_dev"}
              />
              <SocialLink
                title={"Dev.to"}
                icon={DevToIcon}
                href={"https://dev.to/mobileui"}
              />
              <SocialLink
                title={"YouTube"}
                icon={YouTubeIcon}
                href={"https://www.youtube.com/channel/UCntaorITuFEV647SHVFHbPg"}
              />
              <SocialLink
                title={"Spectrum"}
                icon={SpectrumIcon}
                href={"https://spectrum.chat/mobileui"}
              />
            </div>

            <div className={classes.links}>
              <Grid container justify={"flex-start"} alignItems={"flex-start"}>
                <Grid item>
                  <Button variant={"text"} color={"secondary"}
                    className={classes.buttonLink}
                    href="https://www.nevernull.io"
                    title={"NeverNull GmbH"}
                  >
                    © {new Date().getFullYear()} NeverNull GmbH
                  </Button>
                  <span className={classes.divider}>|</span>
                  <ButtonLink
                    className={classes.buttonLink}
                    to={"/privacy-policy"}
                    title={"Privacy Policy"}
                  >
                    Privacy Policy
                  </ButtonLink>
                  <Hidden xsDown>
                    <span className={classes.divider}>|</span>
                  </Hidden>
                </Grid>
                <Grid item xs={12} sm>
                  <ButtonLink
                    className={classes.buttonLink}
                    to={"/trademarks"}
                    title={"Trademarks"}
                  >
                    Trademarks
                  </ButtonLink>
                  <span className={classes.divider}>|</span>
                  <ButtonLink
                    className={classes.buttonLink}
                    to={"/terms-of-service"}
                    title={"Terms of Service"}
                  >
                    Terms of Service
                  </ButtonLink>
                  <span className={classes.divider}>|</span>
                  <ButtonLink
                    className={classes.buttonLink}
                    to={"/legal-notice"}
                    title={"Legal Notice"}
                  >
                    Legal Notice
                  </ButtonLink>
                </Grid>
              </Grid>
            </div>
        </div>
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Container>
    </footer>
  )
}

export default Footer
