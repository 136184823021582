import React, { useEffect, useState } from "react"

const useScrollFromTop = (top) => {
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const limit = (window.scrollY || document.documentElement.scrollTop) < top
      if (limit !== isTop) {
        setIsTop(limit)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return [isTop]
}

export default useScrollFromTop
