/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, lang, meta, keywords, title, isFrontPage, metaType,  image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            author
            siteUrl
            defaultImage: image
            twitterUsername
            defaultKeywords: keywords
          }
        }
      }
    `
  )

  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
    defaultKeywords,
  } = site.siteMetadata

  const metaDescription = description || defaultDescription
  const metaTitleTemplate = isFrontPage
    ? `${site.siteMetadata.title}`
    : `%s | ${site.siteMetadata.title}`
  const metaImage = `${siteUrl}${image || defaultImage}`
  const metaTitle = `${title ? title + " | MobileUI" : defaultTitle}`
  const metaKeywords = keywords || defaultKeywords

  // console.log({
  //   metaImage,
  //   siteMetadata: site.siteMetadata,
  //   metaTitle
  // })

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title>{metaTitle}</title>
      {/*<link rel="canonical" href={siteUrl} />*/}
      <meta name="keywords" content={metaKeywords} />

      <meta name="description" content={metaDescription} />
      {metaImage && <meta name="image" content={metaImage} />}

      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content={metaType} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      {metaImage && <meta property="og:image" content={metaImage} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterUsername} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      {metaImage && <meta name="twitter:image" content={metaImage} />}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  metaType: "website"
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
