import { Button } from "@material-ui/core"
import React from "react"
import { Link } from "gatsby"

const ButtonLink = ({ to, title, children, ...props }) => {
  return (
    <Button
      {...props}
      to={to}
      variant={"text"}
      size={"large"}
      color={"secondary"}
      component={Link}
      title={title}
    >
      {children}
    </Button>
  )
}

export default ButtonLink
