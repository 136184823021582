import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import {
  getInMemoryAuthToken,
  getRefreshToken,
  isLoggedOut,
  isTokenExpired,
  LOGGED_OUT_KEY,
  logout,
  setAuthToken,
} from "../services/auth"
import { gql, useMutation } from "@apollo/client"
import useNetwork from "./useNetwork"
import { navigate } from "gatsby"
import uuid from "uuid"
import { connect, useDispatch } from "react-redux"
import * as actionType from "../state/actionTypes"
import { setToken } from "../state/actions"

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  return (
  <AuthContext.Provider value={useProvideAuth()}>
    {children}
  </AuthContext.Provider>
)}


export default AuthProvider

export const useAuth = () => useContext(AuthContext)

const syncLoginStatus = (event, setToken) => {
  if (event.key === LOGGED_OUT_KEY && isLoggedOut()) {
    logout(navigate("/dashboard/"), setToken)
  }
}

const REFRESH_TOKEN = gql`
  mutation LoginUser($input: RefreshJwtAuthTokenInput!) {
    refreshJwtAuthToken(input: $input) {
      authToken
    }
  }
`

const LOGOUT_USER = gql`
    mutation LogoutUser($input: LogoutInput!) {
        logout(input: $input) {
            status
        }
    }
`

const useProvideAuth = () => {
  const [refreshToken, { data }] = useMutation(REFRESH_TOKEN)
  const [logoutUser, { logoutData }] = useMutation(LOGOUT_USER)


  // In Memory Token
  const [token, setToken] = useState(null)

  const isOnline = useNetwork()

  const isLoggedIn = () =>
    token && !isTokenExpired(token)

  useEffect(() => {
    if (isOnline && getRefreshToken()) {
      refreshToken({
        variables: {
          input: {
            clientMutationId: uuid(),
            jwtRefreshToken: getRefreshToken(),
          },
        },
      }).then((response) => {
        const localToken = response?.data?.refreshJwtAuthToken?.authToken

        if (localToken) {
          // console.log("setAuthToken")
          setToken(localToken)
          setAuthToken(localToken)
        }
      })
    }
  }, [refreshToken])


  const authLogout = () => {
    logoutUser({
      variables: {
        input: {
          clientMutationId: uuid(),
        },
      },
    }).then((response) => {
      // logout()
      console.log(response)
    })
  }

  /**
   * Make sure, User is logged out on all Tabs
   */
  useEffect(() => {
    window.addEventListener("storage", (e) => syncLoginStatus(e, setToken))

    return () => {
      window.removeEventListener("storage", (e) => syncLoginStatus(e, setToken))
    }
  })

  // TODO: this still needs to be implemented properly
  // useEffect(() => {
  //   console.log("useEffect called")
  //   if (isAuthTokenExpired() && isOnline) {
  //     console.log("Triggered Token Refresh")
  //     /**
  //      * Execute an arbitrary query to trigger auth token refresh,
  //      * then sync local storage with auth context.
  //      */
  //     client
  //     .query({
  //       query: gql`
  //         {
  //           generalSettings {
  //             title
  //           }
  //         }
  //       `,
  //     })
  //   }
  // })

  return {
    isLoggedIn,
    isOnline,
    logout: authLogout,
    token,
    setToken
    // user: token ? token.user : null,
  }
}

