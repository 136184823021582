import * as actionType from "./actionTypes"

/*
 * action creators
 */


export const setUser = (user) => ({
  type: actionType.SET_USER,
  payload: user
})

export const resetUser = () => ({
  type: actionType.RESET_USER,
})
